import { HStack, Box, Center } from '@chakra-ui/react'

import { Logo } from './Logo'
import { Navlinks } from './Navlinks'
import { Socials } from './Socials'

export const Navbar = () => (
  <Center>
    <HStack
      justify='space-between'
      backgroundColor='rgb(33,33,33)'
      color='white'
      py='10px'
      px={{ base: '10px', sm: '20px', md: '30px' }}
      borderRadius='0px 0px 20px 20px'
      w={{ base: '100%', xl: '50%' }}
      fontSize={{ base: 'sm', sm: 'xl' }}
      zIndex='2'
    >
      <Logo />
      <Navlinks />
      <Socials />
    </HStack>
  </Center>
)
