import {
  ChakraProvider,
  theme,
  Container,
  Box,
  Image,
  Center,
  Text,
} from '@chakra-ui/react'

import { Navbar } from './Navbar'
import { Home } from './Home'

import './styles/App.css'

export const App = () => (
  <ChakraProvider theme={theme}>
    <Box id='home'>
      <Navbar />
      <Center>
        <Image
          src='../band3.png'
          alt='top band photo'
          w='90%'
          mt='-60px'
          pb='20px'
        />
      </Center>
      <Container maxW='4xl' fontSize='xl' bg='rgb(45,45,45)' p='0'>
        <Home />
      </Container>
    </Box>
  </ChakraProvider>
)
