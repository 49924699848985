import {
  Container,
  Heading,
  Stack,
  Text,
  Input,
  FormControl,
  Button,
  Center,
} from '@chakra-ui/react'

export const Contact = () => (
  <Center>
    <Stack
      id='contact'
      color='white'
      spacing='30'
      pb='30px'
      textAlign='center'
      w='50%'
    >
      <Heading size='xl' fontFamily='Miramar'>
        Contact
      </Heading>
      <Text fontSize='xl'>
        MANAGEMENT & BOOKING <br /> groovelabfunk@gmail.com
      </Text>
      <FormControl>
        <Input placeholder='Email address' type='email' />
        <Button type='submit' w='100%' mt='6px' colorScheme='teal'>
          Subscribe to our mailing list!
        </Button>
      </FormControl>
    </Stack>
  </Center>
)
