import { Stack, Text, Heading, Link, Divider, Center } from '@chakra-ui/react'

export const Shows = () => (
  <Stack id='shows' color='white' spacing='30' pb='30px' textAlign='center'>
    <Heading fontFamily='Miramar' size='xl'>
      Upcoming Shows
    </Heading>
    <Divider />
    <Center>
      <Stack width='50%' ml='auto' mr='auto' spacing='1'>
        <Text fontSize='lg' px='30px'>
          Dec 17th
        </Text>
        <Text fontSize='xl' fontWeight='bold'>
          The Juice Box
        </Text>
        <Text fontSize='md'>Centralia WA</Text>
        <Link fontSize='md'>Info</Link>
      </Stack>
    </Center>
    <Center>
      <Divider w='50%' />
    </Center>
  </Stack>
)
