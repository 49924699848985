import { Icon, Link, HStack } from '@chakra-ui/react'
import { spotify, facebook, instagram } from './icons'

export const Socials = () => (
  <HStack>
    <Link
      href='https://open.spotify.com/artist/1ZgLbFbXgiDrg0R9JEFdvF?si=gbyUrjIIRyK8wkxeOPT4mA'
      isExternal
    >
      <Icon boxSize={{ base: '25px', lg: '30px' }}>{spotify}</Icon>
    </Link>
    <Link href='https://www.facebook.com/groovelabfunk' isExternal>
      <Icon boxSize={{ base: '25px', lg: '30px' }}>{facebook}</Icon>
    </Link>
    <Link href='https://www.instagram.com/groovelabfunk' isExternal>
      <Icon boxSize={{ base: '25px', lg: '30px' }}>{instagram}</Icon>
    </Link>
  </HStack>
)
