import { Image, Link } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'

export const Logo = () => (
  <Link href='#home'>
    <Image
      w={{ base: '60px', lg: '80px' }}
      objectFit='cover'
      src='../logo.png'
      alt='Groove Lab Logo'
    />
  </Link>
)
