import {
  HStack,
  Link,
  Menu,
  IconButton,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react'

import { HamburgerIcon } from '@chakra-ui/icons'

import { Link as RouterLink } from 'react-router-dom'

export const Navlinks = () => (
  <>
    <HStack
      spacing={{ base: '20px', md: '35px' }}
      display={{ base: 'none', sm: 'flex' }}
    >
      <Link href='#shows'>SHOWS</Link>
      <Link isExternal>MERCH</Link>
      <Link href='#bio'>BIO</Link>
      <Link href='#contact'>CONTACT</Link>
    </HStack>
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label='Pages'
        icon={<HamburgerIcon boxSize='20px' />}
        color='black'
        display={{ base: 'flex', sm: 'none' }}
      />
      <MenuList color='black'>
        <MenuItem>
          <Link href='#shows'>SHOWS</Link>
        </MenuItem>
        <MenuItem>
          <Link isExternal>STORE</Link>
        </MenuItem>
        <MenuItem>
          <Link href='#bio'>BIO</Link>
        </MenuItem>
        <MenuItem>
          <Link href='#contact'>CONTACT</Link>
        </MenuItem>
      </MenuList>
    </Menu>
  </>
)
