import { Image, Box, Stack, Center } from '@chakra-ui/react'

import { Contact } from './Contact'
import { Shows } from './Shows'
import { Bio } from './Bio'

export const Home = () => (
  <Box>
    <Stack spacing='10'>
      <Stack spacing='20px' pb='30px'>
        <Center>
          <iframe
            width='560'
            height='315'
            src='https://www.youtube.com/embed/fnT2rpdSkpU'
            title='Bad Brew'
          ></iframe>
        </Center>
        <Center>
          <iframe
            width='560'
            height='315'
            src='https://www.youtube.com/embed/lZMbus4KcAA'
            title='C10'
          ></iframe>
        </Center>
      </Stack>
      <Shows />
      {/* <Merch/> */}
      <Bio />
      <Contact />
    </Stack>
  </Box>
)
