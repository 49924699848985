import { Stack, Text, Heading, Image } from '@chakra-ui/react'

export const Bio = () => (
  <Stack id='bio' color='white' spacing='30px' pb='30px'>
    <Heading textAlign='center' size='xl' fontFamily='Miramar'>
      About Us
    </Heading>
    <Image src='../band.jpg' alt='the band' />
    <Text fontSize='lg' px='30px'>
      Groove Lab is an 8-piece instrumental funk band hailing from Olympia, WA
      boasting a hearty horn trio and powerhouse, locked-in rhythm section. This
      band has a way of getting you to move your feet, even when you didn't
      think you wanted to.
      <br />
      <br />
      As true scientists, members of 'The Lab' are constantly experimenting with
      the sacred formulas of funk to provide you the freshest, grooviest, and
      most other worldly dance tunes curated and crafted, through science,
      specifically for the listener's pleasure.
      <br />
      <br />
      You gotta catch one of these shows when they're in town to truly
      understand what these funky dudes are trying to share with their audience.
    </Text>
  </Stack>
)
